/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import { Divider, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { ButtonCallToAction } from 'components/molecules'
import { Container } from './style'

const PageTitle = ({
  title,
  padding,
  divider,
  reverse,
  isButton,
  valueButton,
  linkCallToAction,
  onClickCallToAction
}) => (
  <>
    <Container reverse={reverse} padding={padding}>
      <Typography component="h1" variant="h5" padding={padding}>
        {title}
      </Typography>
      {isButton && (
        <ButtonCallToAction
          labelCallToAction={valueButton}
          linkCallToAction={linkCallToAction}
          callToAction={onClickCallToAction}
          startIcon={<ArrowBack />}
        />
      )}
    </Container>
    {divider && <Divider />}
  </>
)

export default PageTitle

PageTitle.propTypes = {
  title: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
  divider: PropTypes.bool,
  padding: PropTypes.string,
  isButton: PropTypes.bool,
  valueButton: PropTypes.string,
  linkCallToAction: PropTypes.string,
  onClickCallToAction: PropTypes.func
}
