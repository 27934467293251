import { useSearchParams } from 'react-router-dom'
import { useUserStore } from 'store/GlobalContext'

const useCurrentUser = () => {
  const [searchParams] = useSearchParams()
  const user = useUserStore()

  let currentParams = Object.fromEntries([...searchParams])
  currentParams = {
    professorId:
      user.role === 'professor' ? user?.username : currentParams?.professorId,
    classId: currentParams?.classId,
    disciplineId: currentParams?.disciplineId,
    group: currentParams?.group
  }

  const userHasSomeLevels = (levels = []) => levels.includes(user.level)

  return {
    professorId: currentParams?.professorId,
    disciplineId: currentParams?.disciplineId,
    classId: currentParams?.classId,
    nameUser: user?.name,
    group: currentParams?.group,
    userHasSomeLevels,
    role: user?.role
  }
}

export default useCurrentUser
