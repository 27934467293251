import { styled, Box, css } from '@mui/material'

const Wrapper = styled(Box)`
  ${({ orientation }) => css`
    display: flex;
    gap: 16px;
    flex-direction: ${orientation};
    justify-content: center;
    align-items: center;

    & img {
      max-width: 100%;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: ${orientation === 'column' ? 'center' : 'flex-start'};

      & > p {
        font-size: 20px;
        text-align: ${orientation === 'column' ? 'center' : 'left'};
      }

      & > p:nth-child(1) {
        font-weight: 600;
      }
    }
  `}
`

export { Wrapper }
