import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TabContext, TabPanel } from '@material-ui/lab'
import { accessObjectByString } from 'services/helpers'
import { Tab, TabList } from './styles'

export default function DynamicTabs({
  tabs = [],
  activeTab,
  tabLabelKey = 'label',
  tabValueKey,
  content = true,
  orientation = 'horizontal',
  tabVariant = 'outlined',
  variant,
  scrollButtons,
  sx,
  onChange
}) {
  const [tab, setTab] = useState(0)
  const tabListRef = useRef(null)
  const scrollableContainer = useRef(null)
  const scrollPropperties = useRef({
    speed: 1,
    startX: 0,
    scrollLeft: 0
  })

  useEffect(() => {
    if (variant !== 'scrollable') return

    if (!scrollableContainer.current) {
      const childrenElemens = Array.from(tabListRef.current.children)
      scrollableContainer.current = childrenElemens.find((element) =>
        element.classList.contains('MuiTabs-scroller')
      )
    }

    function isTouchEvent(event) {
      return Array.isArray(event.touches)
    }

    function onMove(event) {
      event.preventDefault()

      const isTouch = isTouchEvent(event)

      const eventX = isTouch ? event.touches[0].pageX : event.pageX
      const currentX = eventX - scrollableContainer.current.offsetLeft
      const walk =
        (currentX - scrollPropperties.current.startX) *
        scrollPropperties.current.speed
      scrollableContainer.current.scrollLeft =
        scrollPropperties.current.scrollLeft - walk
    }

    function onMoveStart(event) {
      const isTouch = isTouchEvent(event)

      scrollPropperties.current.scrollLeft =
        scrollableContainer.current.scrollLeft
      const eventX = isTouch ? event.touches[0].pageX : event.pageX
      scrollPropperties.current.startX =
        eventX - scrollableContainer.current.offsetLeft

      const eventName = isTouch ? 'touchmove' : 'mousemove'
      scrollableContainer.current.addEventListener(eventName, onMove)
    }

    function onMoveEnd(event) {
      const isTouch = isTouchEvent(event)
      const eventName = isTouch ? 'touchmove' : 'mousemove'
      scrollableContainer.current.removeEventListener(eventName, onMove)
    }

    scrollableContainer.current.addEventListener('mousedown', onMoveStart)
    scrollableContainer.current.addEventListener('mouseup', onMoveEnd)
    scrollableContainer.current.addEventListener('mouseleave', onMoveEnd)
    scrollableContainer.current.addEventListener('touchstart', onMoveStart)
    scrollableContainer.current.addEventListener('touchend', onMoveEnd)

    return () => {
      scrollableContainer.current.removeEventListener('mousemove', onMoveStart)
      scrollableContainer.current.removeEventListener('mouseup', onMoveEnd)
      scrollableContainer.current.removeEventListener('mouseleave', onMoveEnd)
      scrollableContainer.current.removeEventListener('touchstart', onMoveStart)
      scrollableContainer.current.removeEventListener('touchend', onMoveEnd)
    }
  }, [variant])

  const handleChange = (_, value) => {
    if (!onChange) {
      setTab(value)
    }
  }

  const tabsSettings = useMemo(() => {
    const tabsToRender = []
    const contentToRender = []

    tabs.forEach(({ icon, onSelect, color, ...currentTab }, index) => {
      const label = accessObjectByString(currentTab, tabLabelKey)
      const value = tabValueKey
        ? accessObjectByString(currentTab, tabValueKey)
        : index

      tabsToRender.push(
        <Tab
          key={`${label}-tab`}
          label={label}
          value={value}
          icon={icon}
          color={color}
          tabVariant={tabVariant}
          onClick={() => {
            if (onChange) onChange(value, currentTab)
            else if (onSelect) onSelect(value, currentTab)
          }}
        />
      )

      if (content) {
        contentToRender.push(
          <TabPanel key={`${label}-tab-content`} value={value}>
            {currentTab.content}
          </TabPanel>
        )
      }
    })

    return { tabsToRender, contentToRender }
  }, [tabs])

  return (
    <TabContext value={activeTab ?? tab}>
      <TabList
        ref={tabListRef}
        tabVariant={tabVariant}
        variant={variant}
        scrollButtons={scrollButtons}
        orientation={orientation}
        onChange={handleChange}
        sx={sx}
      >
        {tabsSettings.tabsToRender}
      </TabList>
      {tabsSettings.contentToRender}
    </TabContext>
  )
}
