import { useEffect, useState, lazy } from 'react'
import { Typography } from '@mui/material'
import { Info, PersonOff } from '@mui/icons-material'
import { useUserStore } from 'store/GlobalContext'
import {
  createStudentsFrequenciesByProfessorAndClass,
  getStudentsFrequenciesByProfessorAndClass,
  updateStudentsFrequenciesByProfessorAndClass
} from 'services/api/professor'
import { useCurrentUser, usePaginationBasic } from 'services/hooks'
import { IsEmpty } from 'services/helpers'
import { ERROR } from 'services/constants'
import { PaginationBasic, TextNotFound } from 'components/atoms'
import { LoadingPage } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import { lessonsOrderedByIndex } from 'components/pages/Professor/services'
import {
  ABSENCE,
  defaultListPerPage,
  ONE_NUMBER,
  PRESENT,
  TEN_NUMBER,
  ZERO_NUMBER,
  messageInformative,
  messageError,
  captionStatus,
  getStatusColor
} from './constants'
import * as Styled from './style'
import { ContentFeedback } from '../style'

const ModalConfirmFrequency = lazy(() =>
  import('./components/ModalConfirmFrequency')
)
const DropoutsTable = lazy(() => import('./components/DropoutsTable'))
const FrequencyTable = lazy(() => import('./components/FrequencyTable'))
const FormNewClass = lazy(() => import('../FormNewClass'))

const Frequency = ({ params }) => {
  const [listStudents, setStudents] = useState([])
  const [listLessons, setLessons] = useState([])
  const [listLessonsConfirmed, setLessonsConfirmed] = useState([])
  const [listDropouts, setDropouts] = useState([])
  const [loadingVisibility, setLoadingVisibility] = useState(true)
  const [openModalAddClass, setOpenModalAddClass] = useState(false)
  const [openConfirmFrequency, setOpenConfirmFrequency] = useState(false)
  const [invalidDate, setInvalidDate] = useState(false)

  const { professorId, disciplineId, classId } = useCurrentUser()
  const { name: professorName } = useUserStore()
  const convertByArray = JSON.parse(`[${disciplineId}]`)

  const formattedParams = {
    id: professorId,
    classId,
    disciplineId: convertByArray
  }

  const { items, handlePrevPage, handleNextPage } = usePaginationBasic(
    lessonsOrderedByIndex(listLessons),
    defaultListPerPage
  )

  const onHandleGetListStudentsFrequencies = async () => {
    setLoadingVisibility(false)

    if (formattedParams.id) {
      const response = await getStudentsFrequenciesByProfessorAndClass(
        formattedParams
      )

      if (response?.error) {
        params?.setSnack(messageError, ERROR)
      } else {
        const { students, lessons, dropouts } = response?.data?.data

        const lessonsFilterByChecked = lessons?.filter(
          ({ checked }) => checked === false
        )

        setStudents([...students])
        setLessons([...lessons])
        setLessonsConfirmed([...lessonsOrderedByIndex(lessonsFilterByChecked)])
        setDropouts([...dropouts])
      }
    }
  }

  const createFrequency = async (
    idRegister,
    disciplinaId,
    doneLesson,
    NumberLesson,
    statusFreuqency
  ) => {
    setLoadingVisibility(true)
    await createStudentsFrequenciesByProfessorAndClass(
      idRegister,
      disciplinaId,
      doneLesson,
      NumberLesson,
      statusFreuqency
    )

    await onHandleGetListStudentsFrequencies()
  }

  const updateFraquency = async (frequencyStatus, frequencyId) => {
    setLoadingVisibility(true)
    if (frequencyStatus === PRESENT)
      return (
        await updateStudentsFrequenciesByProfessorAndClass({
          statusFrequency: ABSENCE,
          frequencyId
        }),
        onHandleGetListStudentsFrequencies()
      )

    return (
      await updateStudentsFrequenciesByProfessorAndClass({
        statusFrequency: PRESENT,
        frequencyId
      }),
      onHandleGetListStudentsFrequencies()
    )
  }

  useEffect(() => onHandleGetListStudentsFrequencies(), [])

  return (
    <>
      <LoadingPage open={loadingVisibility} />
      {formattedParams.id && !IsEmpty(listStudents) && (
        <>
          <Styled.HeaderFrequency>
            {!IsEmpty(listLessonsConfirmed) && (
              <Styled.ButtonConfirmFrequency
                className={TAG_MANAGER.btn_teacher_requests_attendance_Lot}
                value="Confirmar frequências"
                onClick={() => setOpenConfirmFrequency(true)}
              />
            )}

            {listLessons?.length > TEN_NUMBER && (
              <PaginationBasic
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                from={items[ZERO_NUMBER]?.number}
                to={items[items.length - ONE_NUMBER]?.number}
              />
            )}
          </Styled.HeaderFrequency>

          <FrequencyTable
            classesData={listStudents}
            lessons={items}
            setOpenConfirmFrequency={setOpenConfirmFrequency}
            openModalAddClass={setOpenModalAddClass}
            createFrequency={createFrequency}
            updateFraquency={updateFraquency}
          />
        </>
      )}
      {formattedParams.id && IsEmpty(listStudents) && (
        <Styled.Box>
          <TextNotFound icon={<Info />} text={messageInformative} />
        </Styled.Box>
      )}
      {!formattedParams.id && (
        <ContentFeedback
          Icon={<PersonOff color="gray" />}
          orientation="row"
          message="Essa disciplina não possui professor."
          complement="Vincule um professor à disciplina para visualizar o diário."
        />
      )}
      {!IsEmpty(listLessonsConfirmed) && (
        <ModalConfirmFrequency
          lessonsToBeConfirmed={listLessonsConfirmed}
          openConfirmFrequency={openConfirmFrequency}
          loadingVisibility={loadingVisibility}
          isLoad={setLoadingVisibility}
          onHandleGetConfirmedLessons={onHandleGetListStudentsFrequencies}
          setOpenConfirmFrequency={setOpenConfirmFrequency}
          setSnack={params?.setSnack}
        />
      )}
      <FormNewClass
        open={openModalAddClass}
        closeDialog={setOpenModalAddClass}
        classId={classId}
        onHandleGetLessons={onHandleGetListStudentsFrequencies}
        disciplineId={disciplineId}
        professorId={professorId}
        professorName={professorName}
        setSnack={params?.setSnack}
        invalidDate={invalidDate}
        setInvalidDate={setInvalidDate}
      />
      {!IsEmpty(listDropouts) && (
        <>
          <Typography variant="h5" sx={{ margin: '24px 0' }}>
            Alunos desistentes
          </Typography>
          <DropoutsTable dropouts={listDropouts} lessonsDroupts={items} />
        </>
      )}
      <div>
        <Styled.Title>Legenda</Styled.Title>

        {captionStatus.map((item) => (
          <Styled.Caption key={item.value}>
            <Styled.ColorInformation
              sx={{ backgroundColor: getStatusColor(item.value) }}
            />
            <Styled.CaptionTitle>{item.label}</Styled.CaptionTitle>
          </Styled.Caption>
        ))}
      </div>
    </>
  )
}

export default Frequency
