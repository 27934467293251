import { styled, css, Tab as MuiTab } from '@mui/material'
import { TabList as MuiTabList } from '@material-ui/lab'

const TabList = styled(MuiTabList)`
  ${({ theme, tabVariant }) => {
    const isFilled = tabVariant === 'filled'

    return css`
      & .MuiTabs-flexContainer {
        gap: ${isFilled ? '8px' : '0px'};
      }

      & .MuiTabs-indicator {
        background-color: ${theme.palette.primary.main};
      }

      & .MuiTab-root {
        font-size: ${isFilled ? '13px' : '14px'};
        font-weight: ${isFilled ? 500 : 700};
        padding: 6px 12px;
        opacity: 1;
        text-transform: ${isFilled ? 'none' : 'uppercase'};
        border-radius: ${isFilled ? '50px' : '0px'};
        color: ${isFilled ? theme.palette.dark.main : theme.palette.gray.main};
        background-color: ${isFilled
          ? theme.palette.gray.disabled
          : 'transparent'};
      }

      & .MuiTabs-indicator {
        display: ${isFilled ? 'none' : 'block'};
      }

      & .MuiTabs-scrollButtons {
        color: ${theme.palette.primary.main};

        &.Mui-disabled {
          opacity: 0.3;
        }
      }

      & .Mui-selected {
        color: ${isFilled
          ? theme.palette.primary.white
          : theme.palette.primary.main};
        background-color: ${isFilled
          ? theme.palette.primary.main
          : 'transparent'};
      }
    `
  }}
`

const Tab = styled(MuiTab)`
  ${({ color, tabVariant }) => {
    if (tabVariant !== 'filled' || !color) return ''

    return css`
      &.Mui-selected {
        background-color: ${color} !important;
      }
    `
  }}
`

export { TabList, Tab }
