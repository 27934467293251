import { css, styled } from '@mui/material'
import { Accordion } from 'components/atoms'
import { IlustrationFeedback } from 'components/molecules'

const CardAccordion = styled(Accordion)`
  ${({ theme, hasColor, expanded, indicatorColor }) => css`
    box-shadow: none;
    background-color: ${hasColor
      ? theme.palette.primary.lightLite
      : theme.palette.primary.white};

    & .MuiAccordionSummary-root {
      padding: 8px;
    }

    &::before {
      display: none;
    }

    & .MuiAccordionSummary-content {
      display: grid;
      column-gap: 16px;
      align-items: center;
      grid-template-columns: auto 1fr repeat(2, minmax(55px, auto));
      grid-template-rows: auto auto;

      &::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: ${indicatorColor ?? theme.palette.primary.main};
      }

      & > h4 {
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        ${!expanded &&
        css`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        `}
      }

      & > span {
        font-size: 12px;
        font-weight: 400;
        grid-column: 2;
        grid-row: 2;
      }

      & > span::before {
        content: '✏️';
        display: inline-block;
        margin-right: 4px;
      }

      & > div {
        display: grid;
        gap: 4px;
        grid-row: 1 / 3;
        justify-items: center;
        grid-column: 3;

        & + div {
          grid-column: 4;
        }

        & > span {
          font-weight: 700;
          text-align: center;

          & span {
            font-size: 12px;
          }
        }

        & > span + span {
          font-size: 12px;
        }
      }
    }

    & .MuiAccordionDetails-root {
      & > div {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr auto;
        padding: 8px 7% 8px 0px;
        border-top: 1px solid ${theme.palette.gray.disabled};

        & span,
        & > p {
          font-size: 12px;
          font-weight: 700;
        }

        & > span {
          font-size: 14px;

          & > span {
            font-weight: 400;
          }
        }
      }

      & > span {
        font-size: 12px;
        font-weight: 300;
        display: block;
        padding-top: 8px;
        border-top: 1px solid ${theme.palette.gray.disabled};

        &::before {
          content: '📌';
          display: inline-block;
          margin-right: 4px;
        }
      }
    }

    ${theme.breakpoints.down('sm')} {
      & .MuiAccordionSummary-root {
        padding: 4px;
      }

      & .MuiAccordionSummary-content {
        grid-template-columns: auto 1fr auto auto;
        column-gap: 8px;

        &::before {
          width: 8px;
          height: 8px;
        }

        & > h4 {
          font-size: 11px;
          font-weight: 400;
        }

        & > span {
          font-size: 10px;
        }

        & > div {
          & > span {
            font-size: 14px;

            & span {
              font-size: 10px;
            }
          }

          & > span + span {
            font-size: 10px;
          }
        }
      }

      & .MuiAccordionDetails-root {
        & > div {
          & span,
          & > p {
            font-size: 10px;
          }

          & > span {
            font-size: 12px;
          }
        }

        & > span {
          font-size: 10px;
        }
      }
    }
  `}
`

const CardIlustrationFeedback = styled(IlustrationFeedback)`
  ${({ theme }) => css`
  
  ${theme.breakpoints.down('sm')} {
    img {
      max-width: 80px;
    }

    & > div > p {
        font-size: 10px;
    }
`}
`

export { CardAccordion, CardIlustrationFeedback }
