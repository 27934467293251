import { IlustrationFeedback } from 'components/molecules'
import styled from 'styled-components'

const Content = styled.div`
  margin-bottom: 20px;
  margin-top: 90px;
`

const ContentFeedback = styled(IlustrationFeedback)`
  margin-top: 80px;

  & > div > p {
    font-size: 14px !important;
    color: ${({ theme }) => theme.palette.dark.light};
  }

  & svg {
    width: 64px;
    height: 64px;
  }
`

export { Content, ContentFeedback }
