import { httpGet } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getGradebook = async ({
  idMatricula,
  typeCourse,
  schoolClassesIds
}) => {
  const params = {
    school_classes_ids: schoolClassesIds
  }
  const getScores = await httpGet(
    `${ENDPOINT.STUDENT}/${idMatricula}/gradebook?types[]=${typeCourse}`,
    { params }
  )

  return getScores
}

export const getGradebookByRegister = async (register, params) => {
  const getScores = await httpGet(
    `${ENDPOINT.STUDENT}/${register}/gradebook`,
    {
      params
    },
    'v2'
  )

  return getScores
}

export const getSubjects = async ({ disciplineId }) => {
  const getSubject = await httpGet(
    `${ENDPOINT.STUDENT_SUBJECTS}/${disciplineId}`
  )

  return getSubject
}
