import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import GlobalProvider from 'store/GlobalProvider'
import ThemeProvider from 'theme/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { MainContext } from 'Context/MainContext'
import { queryClient } from 'services/api/http'
import { QueryClientProvider } from '@tanstack/react-query'
import Routes from './routes'
import 'react-toastify/dist/ReactToastify.css'

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCKUP_MODE === 'true'
) {
  import('services/mocks').then((module) => {
    module.default()
  })
}

const App = () => {
  const gtmParams = {
    id: process.env.REACT_APP_GTM_ID
  }

  return (
    <QueryClientProvider client={queryClient}>
      <GTMProvider state={gtmParams}>
        <GlobalProvider>
          <ThemeProvider>
            <CssBaseline />
            <MainContext>
              <Routes />
            </MainContext>
          </ThemeProvider>
        </GlobalProvider>
      </GTMProvider>
    </QueryClientProvider>
  )
}

export default App
