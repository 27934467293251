import { Suspense } from 'react'
import { LoadingPage } from 'components/molecules'
import { ToastComponent } from 'components/atoms'
import * as Styled from './style'

const TemplateStudentMyScores = ({
  titleHeader,
  content,
  snackAlert,
  ...props
}) => (
  <Suspense fallback={<LoadingPage open />}>
    <Styled.Content {...props}>
      <div>{titleHeader}</div>
      <div>{content}</div>
      {snackAlert && <div>{snackAlert}</div>}
    </Styled.Content>

    <ToastComponent />
  </Suspense>
)

export default TemplateStudentMyScores
