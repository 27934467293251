export const captionStatus = [
  {
    value: 'approved',
    label: 'Aprovado'
  },
  {
    value: 'disapproved',
    label: 'Reprovado'
  },
  {
    value: 'in_progress',
    label: 'Em andamento'
  },
  {
    value: 'next',
    label: 'Próximas'
  }
]

export const bulletinNotes = [
  {
    value: 'subject',
    label: '- Disciplinas com asterisco (*) tem a nota vinculada a outra turma'
  }
]
