import React from 'react'
import { ModalSkeletonActivities } from 'components/molecules'

const ModalSubjectActivities = ({
  open,
  setOpen,
  subjecActivities,
  loadingModal,
  disciplineType
}) => (
  <ModalSkeletonActivities
    open={open}
    onCloseModal={() => setOpen(!open)}
    listPresential={subjecActivities?.face_to_face}
    listOnline={subjecActivities?.online}
    listRecovery={subjecActivities?.recovery}
    listReclassification={subjecActivities?.reclassification}
    onlineScore={subjecActivities?.online_score}
    recoveryScore={subjecActivities?.recovery_score}
    reclassificationScore={subjecActivities?.reclassification_score}
    faceToFaceScore={subjecActivities?.face_to_face_score}
    faults={subjecActivities?.absence}
    faultsAll={subjecActivities?.permitted_abcence}
    total={subjecActivities?.score}
    amount={subjecActivities?.amount}
    messageInfo="A nota é composta pela soma das atividades e avaliações"
    loadingModal={loadingModal}
    disciplineType={disciplineType}
    isVisibleLink={false}
  />
)
export default ModalSubjectActivities
