import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { createContext, useState, useContext } from 'react'
import { listProfessorsByDisciplines } from '../services'

const initialState = {
  name: '',
  dateStart: '',
  dateEnd: '',
  shift: '',
  code: ''
}
const state = {
  professors: [],
  findProfessorsByDisciplines: ({ disciplinesIds }) => {}
}

export const HeaderContext = createContext({ ...initialState, ...state })

export function DiaryProvider({ children }) {
  const { setLoading } = useLoading()
  const [resumeHead, setResumeHead] = useState(initialState)
  const [professors, setProfessors] = useState(state.professors)

  const findProfessorsByDisciplines = async ({ disciplinesIds }) => {
    setLoading(true)
    const professorsData = await listProfessorsByDisciplines({
      disciplinesIds,
      onError: () => setLoading(false)
    })

    if (professorsData) {
      const filteredProfessors = professorsData.filter(({ id }) => !!id)
      setProfessors(filteredProfessors)
    }

    setLoading(false)
  }

  return (
    <HeaderContext.Provider
      value={{
        resumeHead,
        setResumeHead,
        findProfessorsByDisciplines,
        professors
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useDiary = () => useContext(HeaderContext)
