import { Box, styled } from '@mui/material'

const ListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ spacing }) => spacing ?? '0px'};
  overflow: auto;
  min-height: 200px;

  & .list-utilitary {
    align-self: center;
    margin-top: 20px;
  }

  & button.list-utilitary {
    text-transform: none;
  }
`
export { ListWrapper }
