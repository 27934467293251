import { Box, CircularProgress, Typography } from '@mui/material'
import { Fragment, useCallback } from 'react'
import Button from '../Button'
import { useDebounceCallback } from 'services/hooks'
import { accessObjectByString } from 'services/helpers'
import { ListWrapper } from './styles'

export default function PaginatedList({
  data = [],
  paginationType = 'dispatch',
  spacing,
  page = 1,
  totalPage = 0,
  onChangePage,
  loading = false,
  componentKey,
  renderComponent,
  EmptyComponent,
  emptyMessage = 'Nenhum item econtrado',
  endMessage = 'Não há mais items encontrados',
  ...props
}) {
  const debouncedPage = useDebounceCallback(() => onChangePage(page + 1), 500)

  const detectScroll = useCallback(
    (e) => {
      if (
        e.target.scrollTop + e.target.clientHeight >=
        e.target.scrollHeight - 2
      ) {
        if (page < totalPage) {
          debouncedPage()
        }
      }
    },
    [page, totalPage]
  )

  const isEmpty = !data.length && !loading
  const isEnd = page === totalPage && !!data.length && !loading
  const hasAction =
    paginationType === 'dispatch' && !isEmpty && !isEnd && !loading

  return (
    <ListWrapper
      {...props}
      spacing={spacing}
      onScroll={paginationType === 'scroll' ? detectScroll : undefined}
    >
      {data.map((value, index) => (
        <Fragment
          key={
            componentKey
              ? accessObjectByString(value, componentKey)
              : `list-component-${index}`
          }
        >
          {renderComponent(value, index)}
        </Fragment>
      ))}
      {isEmpty ? (
        EmptyComponent ? (
          EmptyComponent
        ) : (
          <Typography className="list-utilitary">{emptyMessage}</Typography>
        )
      ) : null}
      {isEnd && (
        <Typography className="list-utilitary">{endMessage}</Typography>
      )}
      {loading && (
        <CircularProgress
          color="primary"
          className="list-utilitary"
          size={40}
        />
      )}
      {hasAction && (
        <Button
          variant="text"
          className="list-utilitary"
          onclick={debouncedPage}
        >
          Ver mais
        </Button>
      )}
    </ListWrapper>
  )
}
