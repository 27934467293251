export const statusProgress = [
  {
    value: 'all',
    label: 'Tudo',
  },
  {
    value: 'approved',
    label: 'Aprovado',
  },
  {
    value: 'disapproved',
    label: 'Reprovado',
  },
  {
    value: 'studying',
    label: 'Em andamento',
  },
  {
    value: 'to_attend',
    label: 'Próximas',
  },
]
