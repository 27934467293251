import React from 'react'
import { Wrapper } from './styles'
import { Box, Typography } from '@mui/material'

export default function IlustrationFeedback({
  Icon,
  src,
  message,
  complement,
  orientation = 'column',
  ...props
}) {
  return (
    <Wrapper orientation={orientation} {...props}>
      {src ? <img src={src.path} alt={src.alt} /> : Icon}
      <Box>
        <Typography>{message}</Typography>
        <Typography>{complement}</Typography>
      </Box>
    </Wrapper>
  )
}
