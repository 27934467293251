import { Snackbar } from 'components/molecules'
import { useSnack } from 'services/hooks'
import { PageTitle, PaginatedList } from 'components/atoms'
import { useNavigate } from 'react-router-dom'
import { DynamicTabs } from 'components/organisms'
import { useQuery } from '@tanstack/react-query'
import discipleneQueryKeys from 'services/api/student/discipline/queries'
import {
  getGradebookByRegister,
  getCoursesByStudent
} from 'services/api/student'
import { useMemo, useState } from 'react'
import { getUserFromLocalStorage } from 'services/helpers'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { defaultMessageForUser, ERROR, INFO } from 'services/constants'
import { ilustrationFeedbacks, tabsFilter } from './constants'
import { ListIlustrationFeedback, Wrapper } from './styles'
import { DisciplineCard } from './components'
import scoresQueryKeys from 'services/api/student/myScores/queries'
import { Box } from '@mui/material'

const MyScores = () => {
  const navigate = useNavigate()
  const { setLoading } = useLoading()
  const { snack, setSnack, snackOpen } = useSnack()
  const user = useMemo(() => getUserFromLocalStorage(), [])
  const [course, setCourse] = useState(null)
  const [disciplines, setDisciplines] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [filters, setFilters] = useState({
    type: 'studying',
    page: 1,
    limit: 10
  })

  const coursesQuery = useQuery({
    queryKey: discipleneQueryKeys.coursesByStudent([user.username]),
    queryFn: () => {
      setLoading(true)
      return getCoursesByStudent(user.username)
    },
    onSuccess: (response) => {
      if (!response.data.length) {
        setSnack('Nenhum curso foi encontrado.', INFO)
      } else {
        setCourse(response.data[0])
      }
    },
    onError: () => {
      setSnack(defaultMessageForUser, ERROR)
    },
    onSettled: () => {
      setLoading(false)
    }
  })

  const gradeBooksQuery = useQuery({
    queryKey: scoresQueryKeys.gradebooks([
      user.username,
      course?.school_classes_ids,
      filters
    ]),
    enabled: !!course && !!course.school_classes_ids,
    queryFn: () =>
      getGradebookByRegister(user.username, {
        school_classes_ids: course.school_classes_ids,
        ...filters
      }),
    onSuccess: (response) => {
      setTotalPage(Math.ceil(response.data.count / filters.limit))

      if (filters.page === 1) {
        setDisciplines(response.data.data)
      } else {
        setDisciplines((disciplines) => disciplines.concat(response.data.data))
      }
    },
    onError: () => {
      setDisciplines([])
      setSnack(defaultMessageForUser, ERROR)
    }
  })

  console.log(filters.page, totalPage)

  return (
    <Wrapper component="main">
      <Snackbar
        message={snack.message}
        severity={snack.severity}
        shouldOpen={snackOpen}
      />
      <Box>
        <PageTitle
          title="Notas e Faltas"
          isButton
          reverse
          onClickCallToAction={() => navigate(-1)}
        />
        <DynamicTabs
          activeTab={course?.course_id}
          onChange={(_, course) => {
            setFilters((filters) => ({ ...filters, type: 'studying', page: 1 }))
            setCourse(course)
          }}
          tabs={coursesQuery.data?.data ?? []}
          tabValueKey="course_id"
          tabLabelKey="name"
          variant="scrollable"
          scrollButtons="off"
          content={false}
        />
        {!!course?.school_classes && (
          <DynamicTabs
            activeTab={filters.type}
            onChange={(value) =>
              setFilters((filters) => ({ ...filters, type: value, page: 1 }))
            }
            tabs={tabsFilter}
            tabValueKey="value"
            tabVariant="filled"
            variant="scrollable"
            scrollButtons="off"
            content={false}
          />
        )}
      </Box>
      {!!course?.school_classes ? (
        <PaginatedList
          data={disciplines}
          loading={gradeBooksQuery.isFetching}
          page={filters.page}
          totalPage={totalPage}
          onChangePage={(page) =>
            setFilters((filters) => ({ ...filters, page }))
          }
          sx={{
            overflow: 'visible'
          }}
          componentKey="disciplineInfo.id"
          renderComponent={(item, index) => (
            <DisciplineCard
              data={item}
              hasColor={index % 2 === 0}
              indicatorColor={ilustrationFeedbacks[item.grades.type].color}
            />
          )}
          EmptyComponent={
            <ListIlustrationFeedback
              src={ilustrationFeedbacks[filters.type].src}
              message={ilustrationFeedbacks[filters.type].message}
              complement={ilustrationFeedbacks[filters.type].complement}
            />
          }
          endMessage={null}
        />
      ) : (
        <ListIlustrationFeedback
          src={ilustrationFeedbacks.is_not_studying.src}
          message={ilustrationFeedbacks.is_not_studying.message}
          complement={ilustrationFeedbacks.is_not_studying.complement}
        />
      )}
    </Wrapper>
  )
}

export default MyScores
