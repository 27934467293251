import React from 'react'
import { Formik } from 'formik'
import { Box, MenuItem } from '@mui/material'
import TAG_MANAGER from 'tagManager'
import { useAnalytics } from 'Context/AnalyticsContext'
import { STUDENT_EVENTS } from 'events/student'
import { statusProgress } from './mock'
import { defaultPage, statusInitially } from './constants'
import * as Styled from './style'

const initialState = {
  status: statusInitially
}

const FormNotes = ({ setType, setPage, nameCourse }) => {
  const analytics = useAnalytics()

  const onHandleSelectCourse = (e, setFieldValue) => {
    analytics.logEvent(STUDENT_EVENTS.gradesAndAbsences.select.result)
    const { value } = e.target
    setFieldValue('status', value)
    setType(value)
    setPage(defaultPage)
  }

  return (
    <>
      <Styled.Header>
        <Formik initialValues={initialState} enableReinitialize>
          {({ values, setFieldValue }) => (
            <Box>
              <Styled.Select
                className={TAG_MANAGER.student_select_filters_result_Bulletin}
                labelId="select-status_id"
                name="status"
                value={values?.status ?? ''}
                onChange={(e) => onHandleSelectCourse(e, setFieldValue)}
                fullWidth
              >
                {statusProgress.map((item) => (
                  <MenuItem key={item.value} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Styled.Select>
            </Box>
          )}
        </Formik>
      </Styled.Header>
    </>
  )
}

export default FormNotes
