import React from 'react'
import { Typography } from '@mui/material'
import { bulletinNotes } from './mock'
import * as Styled from './style'

const BulletinNotes = () => (
  <Styled.BulletinNotes>
    <div>
      <Styled.Title>Observações do Boletim</Styled.Title>
      {bulletinNotes.map((item) => (
        <div key={item.value}>
          <Typography variant="subtitle2">{item.label}</Typography>
        </div>
      ))}
    </div>
  </Styled.BulletinNotes>
)

export default BulletinNotes
