import { styled, Box, css } from '@mui/material'
import { IlustrationFeedback } from 'components/molecules'

const Wrapper = styled(Box)`
  margin-top: 64px;

  & > div:nth-child(1) {
    position: sticky;
    top: 64px;
    width: 100%;
    background-color: #fafafa;
    z-index: 1;
    padding: 24px 0 12px 0;

    & > div:nth-child(2) {
      margin: 12px 0 32px 0;
    }
  }
`

const ListIlustrationFeedback = styled(IlustrationFeedback)`
  ${({ theme }) => css`
  margin-top: 60px;
  
  ${theme.breakpoints.down('sm')} {
    margin-top: 30px;

    img {
      max-width: 60%;
    }

    & > div > p {
        font-size: 14px;
    }
`}
`

export { Wrapper, ListIlustrationFeedback }
