import theme from 'theme/designTokens'

const tabsFilter = [
  {
    label: 'Em andamento',
    value: 'studying'
  },
  {
    label: 'Tudo',
    value: 'all',
    color: theme.palette.hub.darkBlue
  },
  {
    label: 'Aprovado',
    value: 'approved',
    color: theme.palette.green.main
  },
  {
    label: 'Reprovado',
    value: 'disapproved',
    color: theme.palette.yellow.warning
  },
  {
    label: 'Próximas',
    value: 'to_attend',
    color: theme.palette.hub.blue
  }
]

const defaultIlustrationFeedback = {
  color: theme.palette.primary.main,
  message: 'Você não possui disciplinas em andamento!',
  src: {
    path: '/images/drink-coffee-ilustration.svg',
    alt: 'Ilustração de relaxamento.'
  }
}

const ilustrationFeedbacks = {
  studying: defaultIlustrationFeedback,
  all: defaultIlustrationFeedback,
  is_not_studying: {
    message: 'Opa! Esse curso ainda não começou.',
    complement:
      'Assim que começar, você poderá acompanhar suas notas e faltas por aqui!',
    src: {
      path: '/images/time-clock-ilustration.svg',
      alt: 'Ilustração de tempo.'
    }
  },
  approved: {
    color: theme.palette.green.main,
    message: 'Opa! Esse curso ainda não começou.',
    complement:
      'Continue se esforçando que em breve você verá suas disciplinas aprovadas!',
    src: {
      path: '/images/walking-dog-ilustration.svg',
      alt: 'Ilustração de passeio.'
    }
  },
  disapproved: {
    color: theme.palette.yellow.warning,
    message: 'Uau! Você não foi reprovado(a) em nenhuma disciplina. Parabéns!',
    src: {
      path: '/images/hobby-ilustration.svg',
      alt: 'Ilustração de um hobby.'
    }
  },
  to_attend: {
    color: theme.palette.hub.blue,
    message: 'Será que vem formatura por aí? Nenhuma nova disciplina à vista!',
    src: {
      path: '/images/adventure-ilustration.svg',
      alt: 'Ilustração de uma aventura.'
    }
  }
}

export { tabsFilter, ilustrationFeedbacks }
