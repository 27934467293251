import styled from 'styled-components'
import { Box, Typography } from '@mui/material'

const Container = styled(Box)`
  display: grid;
  align-items: center;
  gap: 8px;
  padding: ${({ padding }) => padding ?? '0 0 24px'};
  grid-template: ${({ reverse }) =>
    reverse ? '"button title" / auto 1fr' : '"title button" / 1fr auto'};

  & > h1 {
    grid-area: title;
  }

  & > button {
    grid-area: button;
    margin: 0px !important;

    &.MuiButton-root {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.primary.main};
      box-shadow: none;

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.white};
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
`

export { Container }
