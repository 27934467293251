import { ExpandMore } from '@mui/icons-material'
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion
} from '@mui/material'
import React, { useMemo } from 'react'

export default function Accordion({
  expanded,
  onChange,
  HeaderComponent,
  BodyComponent,
  hasIcon = true,
  expandIcon,
  ...props
}) {
  const icon = useMemo(() => {
    if (!hasIcon) return null
    if (expandIcon) return expandIcon
    return <ExpandMore />
  }, [hasIcon, expandIcon])

  return (
    <MuiAccordion expanded={expanded} onChange={onChange} {...props}>
      <AccordionSummary expandIcon={icon}>{HeaderComponent}</AccordionSummary>
      <AccordionDetails>{BodyComponent}</AccordionDetails>
    </MuiAccordion>
  )
}
