import { useEffect, useState, lazy } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ModalConfirm } from 'components/molecules'
import { ROUTE } from 'services/constants'
import { useMakeService } from 'services/hooks'
import TAG_MANAGER from 'tagManager'
import { isEmpty } from 'services/helpers'
import { breadcrumbsItems, initialState } from './constants'
import {
  createByUpdateSubjects,
  getSubjectById,
  listJoyCourses
} from './services'
import * as Styled from './style'

const Form = lazy(() => import('./Form'))

const CreateByUpdateDiscipline = () => {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [disciplineJoy, setDisciplineJoy] = useState([])
  const [subjectEdit, setSubject] = useState(initialState)
  const [levelCourse, setLevelCourse] = useState([])

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { isEdit } = params
  const { disciplineCode: numberSubjects, id: idSubject } = params
  const hasCreateSubject = location?.state?.newDiscipline

  const { level } = useMakeService({ isLevel: true })

  const handleConfirme = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_MANAGEMENT_SUBJECTS, {
      state: { savedSearch: true }
    })
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: subjectEdit,
    onSubmit: (values) => {
      createByUpdateSubjects({
        values,
        setLoading,
        navigate,
        isEdit,
        idSubject
      })
    }
  })

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      listJoyCourses({ setLoading, setDisciplineJoy, isEdit })
    }
  }, [])

  useEffect(() => {
    if (idSubject && !isEmpty(disciplineJoy)) {
      getSubjectById({ idSubject, setLoading, setSubject, disciplineJoy })
    }
  }, [disciplineJoy])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      breadcrumbsNotLink={breadcrumbsItems(hasCreateSubject, numberSubjects)}
      classNameHeader={`goBack ${
        location?.state?.newSubject &&
        TAG_MANAGER.gestao_btn_cancela_novaDisciplina
      }`}
      onClickButton={() => setOpenModal(true)}
      content={
        <>
          <Form
            isEdit={isEdit}
            levelCourse={levelCourse}
            level={level}
            navigate={navigate}
            handleSubmit={formik.handleSubmit}
            setLoading={setLoading}
            values={formik.values}
            idSubject={idSubject}
            setFieldValue={formik.setFieldValue}
            setOpenModal={setOpenModal}
            disciplineJoy={disciplineJoy}
            setLevelCourse={setLevelCourse}
          />

          <ModalConfirm
            open={openModal}
            size="medium"
            handleClose={() => setOpenModal(false)}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => setOpenModal(false)}
            onClickConfirm={() => handleConfirme()}
            colorButtonConfirm="error"
            icon={<Styled.IconWarningAmber />}
            title="Cancelar"
            message="Deseja cancelar a operação?
 Você perderá as alterações que ainda não foram salvas."
            classNameConfirm={TAG_MANAGER.gestao_btn_cancela_novaDisciplina}
          />
        </>
      }
    />
  )
}

export default CreateByUpdateDiscipline
