import styled from 'styled-components'
import { Box, Typography, Select as MuiSelect } from '@mui/material'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
`

const Title = styled(Box)`
  margin: 24px 0;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const TitleBold = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    margin-right: 10px;
  }
`

const Select = styled(MuiSelect)`
  min-width: 200px;
`

export { Header, Title, TitleBold, Select }
