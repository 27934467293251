import { IlustrationFeedback } from 'components/molecules'
import styled from 'styled-components'

const Paginate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`

const ContentFeedback = styled(IlustrationFeedback)`
  margin-top: 80px;

  & > div > p {
    font-size: 14px !important;
    color: ${({ theme }) => theme.palette.dark.light};
  }

  & svg {
    width: 64px;
    height: 64px;
  }
`

export { Paginate, ContentFeedback }
