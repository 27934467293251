const cardIlustrationFeedbacks = {
  to_attend: {
    message: 'Opa! Essa disciplina ainda não começou.',
    complement: 'Assim que iniciar, as informações aparecerão aqui.',
    src: {
      path: '/images/stairs-question-ilustration.svg',
      alt: 'Ilustração de uma pessoa pensando.'
    }
  },
  default: {
    message: 'Esta disciplina ainda não possui avaliações!',
    complement: 'Assim que forem registradas, você verá os detalhes aqui.',
    src: {
      path: '/images/loading-ilustration.svg',
      alt: 'Ilustração que indica espera.'
    }
  }
}

export { cardIlustrationFeedbacks }
