/* eslint-disable no-undef */
import { defaultMessageError, defaultMessageForUser } from 'services/constants'
import {
  toastNotificationError,
  toastNotificationErrorServer
} from './nofications'

const HTTP_NOT_FOUND = 404
const HTTP_FORBIDDEN = 403
const HTTP_ERROR_500 = 500
const HTTP_ERROR_502 = 502
const HTTP_ERROR_503 = 503
const HTTP_CORS = 0

const mapErrors = (errors) => {
  if (errors.file) {
    return toastNotificationError('Insira uma imagem de até 5MP', 3000)
  }

  return Object.keys(errors).map((key) => {
    if (typeof errors[key] === 'object') {
      return mapErrors(errors[key])
    }

    if (Array.isArray(errors[key])) {
      return mapErrors(errors[key][0])
    }

    return toastNotificationError(errors[key], 3000)
  })
}

const checkMessages = ({
  messagesError,
  errorDefault,
  messages,
  statusCode
}) => {
  if (messages[statusCode]) {
    return toastNotificationErrorServer(messages[statusCode], 3000)
  }

  if (messagesError) {
    return mapErrors(messagesError)
  }

  return toastNotificationError(errorDefault)
}

export const getErrorMessage = (error, statusCode, feedbackMessage = '') => {
  const errorDefault =
    'Não foi possível executar está ação, tente novamente mais tarde!'

  const messages = {
    [HTTP_NOT_FOUND]: defaultMessageError,
    [HTTP_FORBIDDEN]: error?.message?.error
      ? `${error.message.error} ${feedbackMessage}`
      : defaultMessageError,
    [HTTP_CORS]: defaultMessageError,
    [HTTP_ERROR_500]: defaultMessageForUser,
    [HTTP_ERROR_502]: defaultMessageForUser,
    [HTTP_ERROR_503]: defaultMessageForUser
  }

  const messagesError =
    error?.message || error?.error || error || error?.errors || error

  return checkMessages({ messagesError, errorDefault, messages, statusCode })
}
