import styled from 'styled-components'
import { Box, Chip, Typography } from '@mui/material'

const BulletinNotes = styled(Box)`
  margin: 24px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const ColorInformation = styled(Chip)`
  &.MuiChip-root {
    max-width: 34px;
    max-height: 15px;
    border-radius: 0;
  }
`

const Caption = styled.div`
  display: flex;
  align-items: center;
`

const CaptionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 10px;
    font-size: 14px;
  }
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 16px;
  }
`

export { BulletinNotes, ColorInformation, Caption, CaptionTitle, Title }
