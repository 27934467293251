/* eslint-disable camelcase */
import { httpPost, httpGet } from 'services/api/http'

/* eslint-disable camelcase */
const hasQueryParams = (params) => params && params?.length

const reduceParams = (params) => (stringParams, key) => {
  let paramChaining = stringParams
  paramChaining += key && params[key] ? `${key}=${params[key]}&` : ''
  return paramChaining
}

const getQueryParams = (paramKeys, queryParamsObject) => {
  const queryStrings = paramKeys.reduce(reduceParams(queryParamsObject), '')
  return queryStrings.replace(/&+$/, '')
}

const makeURL = (path, queryStringsObject = {}) => {
  const paramsKeys = Object.keys(queryStringsObject)
  const queryParams = getQueryParams(paramsKeys, queryStringsObject)
  return hasQueryParams(queryParams) ? `${path}?${queryParams}` : path
}

const newAccessLegacy = async (url) => {
  const { REACT_APP_API_HOST } = process.env
  const body = { link: url }
  const response = await httpPost(
    `${REACT_APP_API_HOST}/v1/system/legacy_link`,
    body
  )
  return response
}

const URLAccessCreditCard = async (rowRegister) => {
  const getPROZuser = localStorage.getItem('PROZ.user')
  const { company_id } = JSON.parse(getPROZuser)
  const { REACT_APP_ENV } = process.env

  const host = REACT_APP_ENV !== 'prod' ? REACT_APP_ENV : ''
  const subdomain = `${host}${company_id === 81 ? 'essa' : 'enferminas'}`

  const url = `https://${subdomain}.prozeducacao.com.br/externos/pagarGetNet.php?id=${rowRegister}`

  return window.open(url, '_blank')
}

const makeURLAccessLegacy = async (path, blank) => {
  const getPROZuser = localStorage.getItem('PROZ.user')
  const { company_id } = JSON.parse(getPROZuser)
  const { REACT_APP_ENV } = process.env

  const host = REACT_APP_ENV !== 'prod' ? REACT_APP_ENV : ''
  const subdomain = `${host}${company_id === 81 ? 'essa' : 'enferminas'}`

  const url = `https://${subdomain}.prozeducacao.com.br/${path}`

  const { data } = await newAccessLegacy(url)

  return blank ? window.open(data) : data
}

export { makeURL, makeURLAccessLegacy, URLAccessCreditCard }
